import axios from 'axios'
import { arrayDataChart, arrayDataToken } from '../types'

export const fetchListDataChart = async (): Promise<arrayDataChart> => {
  try {
    const { data: response } = await axios({
      method: 'GET',
      url: `https://dog-watcher-api.deltalabsjsc.com:4001/api/v1/tokens`,
      // url: `http://localhost:4000/api/v1/tokens`,
    })
    const arrayData = []
    for (let i = 0; i < response.data.length; i++) {
      arrayData.push({
        amount: response.data[i].amount,
        buyCurrency: response.data[i].buyCurrency,
        close: Number(response.data[i].close),
        high: response.data[i].high,
        low: response.data[i].low,
        open: Number(response.data[i].open),
        price: response.data[i].price,
        sellCurrency: response.data[i].sellCurrency,
        time: response.data[i].timeInterval,
        trades: response.data[i].trades,
        volume: response.data[i].volume,
      })
    }
    return {
      dataChart: arrayData,
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
    return {
      dataChart: [],
    }
  }
}

export const fetchListData = async (): Promise<arrayDataToken> => {
  try {
    const { data: response } = await axios({
      method: 'GET',
      url: `https://dog-watcher-api.deltalabsjsc.com:4001/api/v1/token`,
      // url: `http://localhost:4000/api/v1/token`,
    })
    const arrayData = []
    for (let i = 0; i < response.data.length; i++) {
      arrayData.push({
        amount: response.data[i].amount,
        buyCurrency: response.data[i].buyCurrency,
        close: Number(response.data[i].close),
        high: response.data[i].high,
        low: response.data[i].low,
        open: Number(response.data[i].open),
        price: response.data[i].price,
        sellCurrency: response.data[i].sellCurrency,
        time: response.data[i].timeInterval,
        trades: response.data[i].trades,
        volume: response.data[i].volume,
      })
    }
    return {
      dataToken: arrayData,
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
    return {
      dataToken: [],
    }
  }
}
