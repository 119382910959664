/* eslint-disable no-nested-ternary */
import { useTranslation } from '@pancakeswap/localization'
import { Box, Card, Heading, Text, Flex } from '@pancakeswap/uikit'
import Page from 'components/Layout/Page'
import { RefreshCreateGlobal } from 'components/Menu/index'
import React, { useContext, useEffect, useState } from 'react'
import { GetDataChart, GetDataToken } from 'state/infoV2'
import styled from 'styled-components'
import { formatAmount } from 'utils/formatInfoNumbers'
import ChartCard from '../components/InfoCharts/ChartCard'
import { getProductClient } from '../components/InfoTables/config'
import { DataChart } from '../components/InfoTables/configChart'
import TeamWalletTable from '../components/InfoTables/TeamWalletTable'
import TrackingWalletTable from '../components/InfoTables/TrackingWalletTable'
import Percent from '../components/Percent'
import { FetchCirculatingSupply, fetchTotalSuppy } from '../hooks/useTotalSupply'
import { RefreshDeleteGlobal } from './Modal/ModalDelete'
import { getTokenLiquidity } from '../hooks/useTokenLiquidity'

const TokenPage: React.FC<React.PropsWithChildren<{ routeAddress: string }>> = ({ routeAddress }) => {
  const { t } = useTranslation()
  const appContext = useContext(RefreshCreateGlobal)
  const contextDelete = useContext(RefreshDeleteGlobal)
  const [totalSupplyValue, setTotalSupplyValue] = useState({ totalSupply: 0 })
  const [walletAddresses, setWalletAddresses] = useState([])
  const [walletInfo, setWalletInfo] = useState([])
  const [circulatingSupplyDisplay, setCirculatingSupplyDisplay] = useState({ circulatingSupply: 0 })
  const [liquidity, setLiquidity] = useState(0)

  useEffect(() => {
    const getSaleItems = async () => {
      try {
        const result = await fetchTotalSuppy()
        setTotalSupplyValue(result)
      } catch (e) {
        console.log(e)
      }
    }
    getSaleItems()
    getProductClient.get('').then((response) => {
      setWalletInfo(response.data.products)
      const addresses = response.data.products.map((wallet) => wallet.address)
      setWalletAddresses(addresses)
    })
  }, [appContext.length, contextDelete.length])

  useEffect(() => {
    const getCirculatingSupplyDisplay = async () => {
      try {
        if (walletAddresses.length > 0) {
          const result = await FetchCirculatingSupply(walletAddresses)
          setCirculatingSupplyDisplay(result)
        }
      } catch (e) {
        console.log(e)
      }
    }
    getCirculatingSupplyDisplay()
  }, [walletAddresses, appContext.length, contextDelete.length])

  const [dataChart] = GetDataChart()
  const [dataToken] = GetDataToken()
  const [volume7day, setvolume7day] = useState(0)
  const [volume24h, setVolume24h] = useState(0)
  const [tx24h, setTx24h] = useState(0)
  const [totalTx, setTotalTx] = useState(0)
  useEffect(() => {
    let volumn7d = 0
    let totalTransaction = 0
    let vol24h = 0
    let transaction24h = 0
    let timeGap = 0
    for (let i = 1; i < 25; i++) {
      timeGap = Math.floor(Date.now() / 1000) - dataToken[dataToken.length - i]?.time
      if (timeGap > 100000) {
        break
      }
      vol24h += dataToken[dataToken.length - i]?.volume
      transaction24h += dataToken[dataToken.length - i]?.trades
    }

    for (let i = 1; i < 169; i++) {
      timeGap = Math.floor(Date.now() / 1000) - dataToken[dataToken.length - i]?.time
      if (timeGap > 604800) {
        break
      }
      volumn7d += dataToken[dataToken.length - i]?.volume
    }
    for (let i = 1; i < dataToken.length; i++) {
      totalTransaction += dataToken[dataToken.length - i]?.trades
    }
    setvolume7day(volumn7d)
    setTotalTx(totalTransaction)
    setVolume24h(vol24h)
    setTx24h(transaction24h)
    const getLiquidity = async () => {
      const liquid = await getTokenLiquidity()
      setLiquidity(liquid)
    }
    getLiquidity()
  }, [dataChart, dataToken])

  const DataToken = {
    exists: false,
    name: 'Run Together Token',
    symbol: 'RUN',
    address: '0xc643e83587818202e0fff5ed96d10abbc8bb48e7',
    volumeUSD: 100,
    volumeUSDChange: 100,
    volumeUSDWeek: 100,
    txCount: dataChart[dataChart.length - 1]?.trades,
    txCountAll: totalTx,
    liquidityToken: 100,
    liquidityUSD: 1000,
    liquidityUSDChange: 1000,
    priceUSD: dataChart[dataChart.length - 1]?.price,
    priceUSDChange: 10,
    priceUSDChangeWeek: 10,
  }

  return (
    // <Provider store={store}>
    <Page symbol={DataToken.symbol}>
      <Flex justifyContent="space-between" mb="24px" flexDirection={['column', 'column', 'row']}>
        <Flex justifyContent={[null, null, 'flex-end']} mt={['8px', '8px', 0]}>
          {/* <SaveIcon fill={watchlistTokens.includes(address)} onClick={() => addWatchlistToken(address)} /> */}
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" flexDirection={['column', 'column', 'column', 'row']}>
        <Flex flexDirection="column" mb={['8px', null]}>
          <Flex alignItems="center">
            <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/19638.png" alt="Token Run" width="32" />
            {/* <CurrencyLogo size="32px" address={address} /> */}
            <Text ml="12px" bold lineHeight="1.5" fontSize="40px" id="info-token-name-title">
              {DataToken.name}
            </Text>
            <Text ml="12px" lineHeight="1" color="textSubtle" fontSize="20px">
              ({DataToken.symbol})
            </Text>
          </Flex>
          <Flex mt="8px" ml="46px" alignItems="center">
            <Text mr="16px" bold fontSize="24px">
              ${formatAmount(dataChart[dataChart.length - 1]?.price, { notation: 'standard' })}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <ContentLayout>
        <Card>
          <Box p="16px">
            <Text bold small color="secondary" fontSize="12px" textTransform="uppercase">
              {t('Liquidity')}
            </Text>
            <Text bold fontSize="16px">
              ${formatAmount(liquidity)}
            </Text>
            {/* <Percent value={0.08} /> */}

            <Text mt="16px" bold color="secondary" fontSize="12px" textTransform="uppercase">
              {t('Volume 24H')}
            </Text>
            <Text bold fontSize="16px" textTransform="uppercase">
              ${formatAmount(volume24h)}
            </Text>
            {/* <Percent value={dataChart[dataChart.length - 1]?.volume / dataChart[dataChart.length - 2]?.volume} /> */}

            <Text mt="16px" bold color="secondary" fontSize="12px" textTransform="uppercase">
              {t('Volume 7D')}
            </Text>
            <Text bold fontSize="16px">
              ${formatAmount(volume7day)}
            </Text>

            <Text mt="24px" bold color="secondary" fontSize="12px" textTransform="uppercase">
              {t('24H / Total transactions')}
            </Text>
            <Text bold fontSize="16px">
              {formatAmount(tx24h, { isInteger: true })} / {totalTx}
            </Text>
            <Text mt="16px" bold color="secondary" fontSize="12px" textTransform="uppercase">
              {t('Total Supply')}
            </Text>
            <Text bold fontSize="16px">
              {formatAmount(totalSupplyValue.totalSupply, { isInteger: true })}
            </Text>
            <Text mt="16px" bold color="secondary" fontSize="12px" textTransform="uppercase">
              {t('Circulating Supply')}
            </Text>
            <Text bold fontSize="16px">
              {formatAmount(Math.round(circulatingSupplyDisplay.circulatingSupply), { isInteger: true })}
            </Text>
          </Box>
        </Card>
        {/* charts card */}
        <ChartCard variant="token" chartData={DataChart} tokenData={DataToken} tokenPriceData={dataChart} />
      </ContentLayout>

      <Heading scale="lg" mb="16px" mt="40px">
        {t('Team Wallets')}
      </Heading>

      <TeamWalletTable />

      <Heading scale="lg" mb="16px" mt="40px">
        {t('Tracking Wallets')}
      </Heading>

      <TrackingWalletTable />
    </Page>
  )
}

export default TokenPage

const ContentLayout = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-gap: 1em;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`
