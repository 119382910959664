/* eslint-disable */
const Web3 = require('web3')

const Web3Client = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/'))
const BNBTokenAddress = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
const BUSDTokenAddress = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'

// The minimum ABI to get ERC20 Token balance
const minABI = [
  // balanceOf
  {
    constant: true,
    inputs: [{ name: '_owner', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: 'balance', type: 'uint256' }],
    type: 'function',
  },
]

const pancakeSwapAbi = [
  {
    inputs: [
      { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
      { internalType: 'address[]', name: 'path', type: 'address[]' },
    ],
    name: 'getAmountsOut',
    outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function',
  },
]

const pancakeSwapContract = '0x10ED43C718714eb63d5aA57B78B54704E256024E'.toLowerCase()

async function calcBNBPrice(amountBNB: number) {
  const bnbToSell = Web3Client.utils.toWei(amountBNB.toLocaleString(), 'ether')
  let amountOutWei
  try {
    const router = await new Web3Client.eth.Contract(pancakeSwapAbi, pancakeSwapContract)
    const amountOut = await router.methods.getAmountsOut(bnbToSell, [BNBTokenAddress, BUSDTokenAddress]).call()
    amountOutWei = Web3Client.utils.fromWei(amountOut[1])
  } catch (error) {
    console.log(error)
  }
  if (!amountOutWei) return 0
  return amountOutWei
}

export const getBalance = async (tokenAddress: string, walletAddress: string) => {
  const contract = new Web3Client.eth.Contract(minABI, tokenAddress)
  const result = await contract.methods.balanceOf(walletAddress).call() // 29803630997051883414242659
  const format: number = Web3Client.utils.fromWei(result) // 29803630.997051883414242659
  return format
}

export const getTokenLiquidity = async () => {
  const poolBNB: number = await getBalance(BNBTokenAddress, '0xb73b2c77052Fd08212865Dc436AE8CeDc200244c')
  const poolBNBPrice: number = await calcBNBPrice(poolBNB)
  const poolBUSD: number = await getBalance(BUSDTokenAddress, '0xB3C793C1470d49569A8d189a946dB5bdB7BB6E4B')
  const totalLiquidity = Number(poolBNBPrice) + Number(poolBUSD)
  return totalLiquidity
}
