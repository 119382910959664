export const DataChart = [
  {
    date: 1670924651,
    volumeUSD: 10,
    liquidityUSD: 10,
  },
  {
    date: 1670924651,
    volumeUSD: 20,
    liquidityUSD: 20,
  },
  {
    date: 1670924651,
    volumeUSD: 30,
    liquidityUSD: 30,
  },
  {
    date: 1670924651,
    volumeUSD: 40,
    liquidityUSD: 40,
  },
  {
    date: 1670924651,
    volumeUSD: 50,
    liquidityUSD: 50,
  },
  {
    date: 1670924651,
    volumeUSD: 60,
    liquidityUSD: 60,
  },
  {
    date: 1670924651,
    volumeUSD: 70,
    liquidityUSD: 70,
  },
  {
    date: 1670924651,
    volumeUSD: 10,
    liquidityUSD: 10,
  },
  {
    date: 1670924651,
    volumeUSD: 40,
    liquidityUSD: 40,
  },
  {
    date: 1670924651,
    volumeUSD: 70,
    liquidityUSD: 70,
  },
  {
    date: 1670924651,
    volumeUSD: 20,
    liquidityUSD: 20,
  },
  {
    date: 1670924651,
    volumeUSD: 90,
    liquidityUSD: 90,
  },
]

export const DataToken = {
  exists: false,
  name: 'Run',
  symbol: 'Run',
  address: '0xc643e83587818202e0fff5ed96d10abbc8bb48e7',
  volumeUSD: 100,
  volumeUSDChange: 100,
  volumeUSDWeek: 100,
  txCount: 100,
  txCountAll: 100,
  liquidityToken: 100,
  liquidityUSD: 1000,
  liquidityUSDChange: 1000,
  priceUSD: 100,
  priceUSDChange: 10,
  priceUSDChangeWeek: 10,
}

export const DataPriceToken = [
  {
    close: 0.008103194697330061,
    high: 0.008103194697330061,
    low: 0.008116015943161697,
    open: 0.008116015943161697,
    time: 1670317200,
  },
  {
    close: 0.008103194697330061,
    high: 0.008103194697330061,
    low: 0.008116015943161697,
    open: 0.008116015943161697,
    time: 1670320800,
  },
  {
    close: 0.018116015943161697,
    high: 0.008103194697330061,
    low: 0.008116015943161697,
    open: 0.008116015943161697,
    time: 1670324400,
  },
  {
    close: 0.018116015943161697,
    high: 0.018116015943161697,
    low: 0.008116015943161697,
    open: 0.008116015943161697,
    time: 1670328000,
  },
  {
    close: 0.008103194697330061,
    high: 0.018116015943161697,
    low: 0.008116015943161697,
    open: 0.008116015943161697,
    time: 1670331600,
  },
  {
    close: 0.008103194697330061,
    high: 0.008103194697330061,
    low: 0.008116015943161697,
    open: 0.018116015943161697,
    time: 1670335200,
  },
  {
    close: 0.008103194697330061,
    high: 0.008103194697330061,
    low: 0.018116015943161697,
    open: 0.008116015943161697,
    time: 1670338800,
  },
  {
    close: 0.008103194697330061,
    high: 0.008103194697330061,
    low: 0.008116015943161697,
    open: 0.008116015943161697,
    time: 1670342400,
  },
  {
    close: 0.018116015943161697,
    high: 0.008103194697330061,
    low: 0.008116015943161697,
    open: 0.018116015943161697,
    time: 1670317200,
  },
]
