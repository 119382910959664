import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from 'state'
import { fetchDataChart, fetchData } from './actions'
import { fetchListDataChart, fetchListData } from './hook/fectData'

export const GetDataChart = () => {
  const data = useSelector<AppState, AppState['infoV2']>((state) => state.infoV2)
  const { dataChart } = data
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    const getDataChart = async () => {
      try {
        const result = await fetchListDataChart()
        dispatch(fetchDataChart(result))
      } catch (e) {
        console.log(e)
      }
    }
    getDataChart()
  }, [dispatch])
  return [dataChart]
}

export const GetDataToken = () => {
  const data = useSelector<AppState, AppState['infoV2']>((state) => state.infoV2)
  const { dataToken } = data
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    const getData = async () => {
      try {
        const result = await fetchListData()
        dispatch(fetchData(result))
      } catch (e) {
        console.log('error')
      }
    }
    getData()
  }, [dispatch])
  return [dataToken]
}
